<template>
  <div>
    <div class="loader-container" v-if="getLoading">
      <CSpinner color="white" class="loader" />
    </div>
    <router-view></router-view>
  </div>
</template>

<script>
import {mapGetters} from "vuex";

export default {
  name: 'App',
  computed: {
    ...mapGetters(['getLoading'])
  }
}
</script>

<style lang="scss">
  @import 'assets/scss/style';
  .loader-container {
    position: fixed;
    z-index: 10000;
    top: 0;
    left: 0;
    background: rgba(0,0,0,0.5);
    width: 100%;
    height: 100%;
  }
  .loader {
    position: fixed;
    z-index: 10001;
    width: 4rem;
    top: 50%;
    left: 50%;
    height: 4rem;
  }
</style>
