<template>
  <div>
    <form class="editForm">
      <div class="form-group">
        <label for="title">Title</label>
        <input v-model="form.title" type="text" class="form-control" id="title" placeholder="Enter a title">
      </div>

      <div class="form-group">
        <label for="url">Url</label>
        <input v-model="form.url" type="text" class="form-control" id="url" placeholder="Enter a UEL">
      </div>

      <div class="form-group">
        <label for="content">Content</label>
        <vue-editor
            id="content"
            useCustomImageHandler
            @image-added="handleImageAdded"
            @image-removed="handleImageRemoved"
            v-model="form.content"
        />
      </div>
      <div class="button_group">
        <button type="button" @click="saveAndCancelEdit()" class="btn btn-primary">Save</button>
        <button type="button" @click="cancelEdit()" class="btn btn-danger">Cancel</button>
      </div>
    </form>
  </div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import { VueEditor } from "vue2-editor";
import axios from "axios";

export default {
  components: { VueEditor },
  name: "EditPage",
  data(){
    return {
      form: {
        id: '',
        title: '',
        url: '',
        content: '',
        file: '',
        image_path: ''
      },
      pageData: {},
      addedImageList: []
    }
  },
  computed: {
    ...mapGetters(['getPageDataById'])
  },
  methods: {
    ...mapActions(['savePageDataAction', 'removeAddedImageFromPage']),

     cancelEdit(){
      if (this.addedImageList.length > 0){
        this.removeAddedImageFromPage(this.addedImageList)
      }
      this.$router.push({
        name: 'PagesConstructor'
      })
    },

    async saveAndCancelEdit(){
      if (this.form.title.trim() && this.form.url.trim()){
        this.form.title = this.form.title.trim()
        this.form.url = this.form.url.trim()
        await this.savePageDataAction(this.form)
        await this.$router.push({
          name: 'PagesConstructor'
        })
      }

    },
    handleImageAdded (file, Editor, cursorLocation, resetUploader) {
      // An example of using FormData
      // NOTE: Your key could be different such as:
      // formData.append('file', file)

      var formData = new FormData();
      formData.append("image", file);
      axios({
        url: "/site-constructor/page/edit/image/added",
        method: "POST",
        data: formData,
        headers: {
          'Content-Type': 'multipart/form-data'
        },
      })
          .then(result => {
            const url = result.data.url; // Get url from response
            Editor.insertEmbed(cursorLocation, "image", url);
            resetUploader();
            this.addedImageList.push(result.data.path)
          })
          .catch(err => {
            throw err
          });
    },

    handleImageRemoved (file) {
      // An example of using FormData
      // NOTE: Your key could be different such as:
      // formData.append('file', file)

      var formData = new FormData();
      formData.append("image", file);
      axios({
        url: "/site-constructor/page/edit/image/removed",
        method: "POST",
        data: formData,
        headers: {
          'Content-Type': 'multipart/form-data'
        },
      })
          .then(result => {
            if(this.addedImageList.includes(result.data.image)){
              this.addedImageList.splice(this.addedImageList.indexOf(result.data.image), 1)
            }
          })
          .catch(err => {
            throw err
          });
    },
  },

  mounted() {
    [this.pageData] = this.getPageDataById
    if (this.pageData){
      this.form.id = this.pageData.id
      this.form.title = this.pageData.title
      this.form.url = this.pageData.url
      this.form.content = this.pageData.content
    }else{
      this.$router.push({
        name: 'PagesConstructor'
      })
    }

  }

}
</script>

<style scoped>
.editForm{
  width: 90%;
  margin: 0 auto;
  padding: 50px 50px;
  border: 8px solid #b3b3b3;
  border-radius: 15px;
}
.button_group{
  display: flex;
}
.button_group button{
  padding: 10px 20px;
  width: 6rem;
  margin-right: 10px;
}
.errorMessage{
  border-color: red;
}

</style>