import axios from 'axios'
export const auth = {
  state: {
    user: {},
    user_role: localStorage.getItem('user_role') ? localStorage.getItem('user_role') : [],
    token: document.cookie.match(new RegExp('(^| )' + 'token_' + '=([^;]+)')) ? document.cookie.match(new RegExp('(^| )' + 'token_' + '=([^;]+)'))[2] : ''
  },
  mutations: {
    setAuth (state, payload) {
      state.token = payload.token
      const date = new Date()
      date.setTime(date.getTime() + (7 * 24 * 60 * 60 * 1000))
      const expires = date.toUTCString()
      document.cookie = `token_ = ${payload.token}; expires = ${expires}; path=/`
      axios.defaults.headers.common = {
        Authorization: payload.token_type + ' ' + payload.token
      }
    },
    removeAuth (state) {
      this.$axios.defaults.headers.common = {
        Authorization: ''
      }
      document.cookie = 'token_=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;';
      state.token = null
    },
    setAuthUser (state, payload) {
      state.user = payload.data.user ? payload.data.user : {}
    }
  },
  actions: {
    async getAuthUserAction (context, data) {
      try {
        const response = await axios.get('/user', {
          headers: {
            'Content-Type': 'application/json',
            Authorization: data.token
          }
        })
        context.commit('setAuthUser', response.data)
        return response.data
      } catch (e) {
        throw e
      }
    },
    async loginUserAction(context, data) {
      try {
        context.commit('changeLoading', true)
         const response = await axios.post('/login', data, {
          headers: {
            'Content-Type': 'application/json; charset=UTF-8'
          }
        })
        if (response.data.token) {
          context.commit('setAuth', response.data)
        }
        context.commit('changeLoading', false)
        return response.data
      } catch (e) {
        context.commit('changeLoading', false)
        throw e
      }
    },
    async logoutAction(context) {
      try {
        context.commit('changeLoading', true)
        const response = await axios.post('/logout')
        context.commit('removeAuth')
        context.commit('changeLoading', false)
        return response.data
      } catch (e) {
        context.commit('changeLoading', false)
        throw  e
      }
    }
  },
  getters: {
    getAuthUser: state => state.user,
    getRegister: state => state.register,
  }
}
