<template>
  <div>
    <table class="table table-hover">
      <thead>
      <tr>
        <th scope="col">#</th>
        <th scope="col">Page Title</th>
        <th scope="col">Page Content</th>
        <th scope="col">Attrs</th>
      </tr>
      </thead>
      <tbody>
        <tr v-for="(item, key) in pagesList" :key="key">
          <th scope="row">{{key + 1}}</th>
          <td>{{item.title}}</td>
          <td class="page_content">{{item.content}}</td>
          <td>
            <button @click="editPage(item.id)" type="button" class="btn btn-warning pt-1 pb-1 pl-5 pr-5">Edit</button>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";

export default {
  name: "Pages",
  data(){
    return {
      pagesList: [],
    }
  },
  methods: {
    ...mapActions(['getPagesDataAction', 'getPageDataByIdAction']),

    async editPage(key){
      await this.getPageDataByIdAction({'id': key})
      await this.$router.push({
        name: 'EditPagesConstructor'
      })
    }
  },
  computed: {
    ...mapGetters(['getPageData'])
  },
  mounted() {
    this.getPagesDataAction().then((res) => {
      this.pagesList = res
    })
  }
}

</script>

<style scoped>
.page_content{
  max-width: 200px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

</style>