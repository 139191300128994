import Vue from 'vue'
import Vuex from 'vuex'
import axios from 'axios'
import { auth } from './modules/auth'
import { global } from './modules/global'
import { siteConstructor } from './modules/siteConstructor'

Vue.use(Vuex)

axios.defaults.headers.common = {
    Authorization: 'Bearer ' + auth.state.token,
    Accept: 'application/json'
}

// axios.defaults.baseURL = 'http://localhost:8889/api/admin/v1/'
axios.defaults.baseURL = 'https://laravel.preprod.bridgestone.we-demonstrate2.ru/api/admin/v1/'

axios.interceptors.response.use((response) => response, ({response}) => {
    if (response.data && response.data.unauthorized) {
        document.cookie = 'token_=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;';
        window.location.href = ''
    }
    global.state.loadingStatus = false
});
export default new Vuex.Store({
        modules: {
            auth,
            global,
            siteConstructor
        }
    }
)
