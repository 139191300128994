import Vue from 'vue'
import Router from 'vue-router'
import { auth as authState } from '@/store/modules/auth'
import Slider from "@/views/site-constructor/Slider";
import Pages from "@/views/site-constructor/Pages"
import EditPage from "@/views/site-constructor/EditPage";

// Containers
const TheContainer = () => import('@/containers/TheContainer')

// Views
const Dashboard = () => import('@/views/Dashboard')


// Views - Pages
const Page404 = () => import('@/views/pages/Page404')
const Login = () => import('@/views/pages/Login')

// Users
const Users = () => import('@/views/users/Users')
const User = () => import('@/views/users/User')

Vue.use(Router)

const router = new Router({
  mode: 'hash',
  linkActiveClass: 'active',
  scrollBehavior: () => ({ y: 0 }),
  routes: configRoutes()
})

function configRoutes () {
  return [
    {
      path: '/',
      name: 'Home',
      redirect: 'dashboard',
      component: TheContainer,
      meta: { requiresAuthAdmin: true },
      children: [
        {
          path: '/dashboard',
          name: 'Dashboard',
          component: Dashboard
        },
        {
          path: 'site-constructor',
          // redirect: '/site-constructor',
          name: 'SiteConstructor',
          component: {
            render (c) { return c('router-view') }
          },
          children: [
            {
              path: 'slider',
              name: 'slider-constructor',
              component: Slider
            },
            //
            {
              path: 'pages',
              name: 'Pages',
              component: {
                render (c) { return c('router-view') }
              },
              children: [
                {
                  path: '/',
                  name: 'PagesConstructor',
                  component: Pages,
                },
                {
                  path: 'edit',
                  name: 'EditPagesConstructor',
                  component: EditPage,
                },
              ]
            },

            //
          ]
        },
        {
          path: 'users',
          meta: {
            label: 'Users'
          },
          component: {
            render(c) {
              return c('router-view')
            }
          },
          children: [
            {
              path: '',
              name: 'Users',
              component: Users
            },
            {
              path: ':id',
              meta: {
                label: 'User Details'
              },
              name: 'User',
              component: User
            }
          ]
        }
      ],
    },
    {
      path: '/',
      redirect: '/login',
      name: 'Pages',
      beforeEnter: (to, from, next) => {
        if (authState.state.token) {
          next({
            name: 'Home'
          })
        } else {
          next()
        }
      },
      component: {
        render (c) { return c('router-view') }
      },
      children: [
        {
          path: '404',
          name: 'Page404',
          component: Page404
        },
        {
          path: 'login',
          name: 'Login',
          component: Login
        }
      ]
    }
  ]
}

router.beforeEach((to, from, next) => {
  const requiresAuthAdmin = to.matched.some(record => record.meta.requiresAuthAdmin)
  if (requiresAuthAdmin && !authState.state.token) {
    next('/login')
  }  else {
    next()
  }
})

export default router