<template>
  <div>
    <CRow class="d-flex justify-content-between">
      <CCol md="7">
          <CCard >
          <CCardHeader>
            <strong>Slider Item</strong>
          </CCardHeader>
          <CCardBody>
            <CForm>
              <CInput
                  label="Slider Header"
                  placeholder="Slider Title"
                  horizontal
                  v-model="$v.form.title.$model"
                  :is-valid="$v.form.title.$error ? (!$v.form.title.$error) : null"
              />
              <CTextarea
                  label="Description"
                  placeholder="Description..."
                  horizontal
                  rows="3"
                  v-model="$v.form.description.$model"
                  :is-valid="$v.form.description.$dirty ? (!$v.form.description.$error) : null"
              />
              <CSelect
                  label="Page"
                  horizontal
                  :options="getSliderPageData.pages"
                  :value.sync="$v.form.page_id.$model"
                  placeholder="Page"
                  :is-valid="$v.form.page_id.$dirty ? (!$v.form.page_id.$error) : null"
              />
              <CInput
                  label="Info Link"
                  placeholder="Link"
                  horizontal
                  v-model="$v.form.more.$model"
                  :is-valid="$v.form.more.$dirty ? (!$v.form.more.$error) : null"
              />
              <CInputFile
                  label="Slide image"
                  horizontal
                  custom
                  @change="changeFileInput"
              />
              <CCard sm="6" md="4" v-show="form.image_path">
                <CCardBody>
                  <img :src="form.image_path" ref="backGround" alt="" height="200" width="200">
                </CCardBody>
              </CCard>
            </CForm>
          </CCardBody>
          <CCardFooter>
            <CButton type="submit" size="sm" color="primary" @click="saveData"><CIcon name="cil-check-circle" /> Save</CButton>
          </CCardFooter>
        </CCard>
        </CCol>
      <CCol md="4">
        <CCard  v-if="showSlider && sliderImages.length">
          <CCardHeader>
            <CIcon name="cil-justify-center"/>
            <strong>Main Slider</strong>
            <div class="card-header-actions">
              <a
                  href="https://coreui.io/vue/docs/components/carousel"
                  class="card-header-action"
                  rel="noreferrer noopener"
                  target="_blank"
              >
              </a>
            </div>
          </CCardHeader>
          <CCardBody>
            <CCarousel
                arrows
                animate
            >
              <div v-for="(sliderImg, index) in sliderImages" :key="index">
                <CCarouselItem
                    :captionHeader="sliderImg.title"
                    :image="sliderImg.image_path"
                    :captionText="sliderImg.description"
                />
                <div  class="sliderButtonNav">
                  <CButton @click="removeItem(index)" class="button-groups" block color="danger">Remove</CButton>
                  <CButton class="button-groups" block color="info" :title="sliderImg.more">More</CButton>
                </div>
              </div>
            </CCarousel>
          </CCardBody>
        </CCard>
        </CCol>
    </CRow>
    <CRow>
      <CCol md="7">
      <CCard  v-if="showSlider && sliderImages.length">
        <CCardHeader>
          <CIcon name="cil-justify-center"/>
          <strong>Drag and Drop positions</strong>
          <div class="card-header-actions">
            <a
                href="https://coreui.io/vue/docs/components/carousel"
                class="card-header-action"
                rel="noreferrer noopener"
                target="_blank"
            >
            </a>
          </div>
        </CCardHeader>
        <CCardBody>
          <div class="drag-drop-list">
            <transition-group name="flip-list" tag="div" class="d-flex flex-wrap">
              <li
                  @dragover="(e) => onDragOver(item, i, e)"
                  @dragend="(e) => finishDrag(item, i, e)"
                  @dragstart="(e) => startDrag(item, i, e)"
                  v-for="(item, i) in sliderImages"
                  class="item"
                  :class="{over: (item === over.item && item !== dragFrom), [over.dir]: (item === over.item && item !== dragFrom) }"
                  draggable="true"
                  :key="i"
              >
                <img :src="item.image_path" :alt="item.title"> </li>
            </transition-group>
          </div>
        </CCardBody>
      </CCard>
      </CCol>
    </CRow>
  </div>
</template>

<script>

import {minLength, required} from "vuelidate/lib/validators"
import { mapActions, mapGetters } from "vuex"

export default {
  name: "Slider",
  data() {
    return {
      form: {
        title: '',
        description: '',
        more: '',
        image_path: '',
        page_id: '',
        file: {}
      },
      showSlider: false,
      sliderImages: [],

      //Drag and drop
      over: {},
      startLoc: 0,
      dragging: false,
      dragFrom: {}
      //End drag and drop

    }
  },
  validations: {
    form: {
      title: {
        required
      },
      page_id: {
        required
      },
      description: {
        required,
        minLength: minLength(6)
      },
      image_path: {
        required
      },
      more: {
        required,
        minLength: minLength(6)
      }
    }
  },
  computed: {
    ...mapGetters(['getSliderPageData'])
  },
  methods: {
    ...mapActions(['saveSliderAction', 'getSliderPageDataAction', 'removeSliderAction']),

    removeItem(index) {

      if (this.sliderImages[index].id){
        let imagePath = this.sliderImages[index].image_path.split('/')
        this.removeSliderAction({'key': this.sliderImages[index].id, 'image_path': imagePath[imagePath.length - 1]})
      }
      this.showSlider = false
      this.sliderImages.splice(index,1)
      this.$nextTick(() => { this.showSlider = true})
    },
    saveData() {
      if (!this.$v.form.$invalid) {
        let requestData = {...this.form}
        const formData = new FormData()
        delete requestData.image_path
        for (const [key, value] of Object.entries(requestData)) {
          formData.append(key, value)
        }
        this.saveSliderAction(formData).then((res) => {
          this.showSlider = false
          this.sliderImages.push(this.form)
          this.sliderImages[this.sliderImages.length - 1].id = res.created.id
          this.sliderImages[this.sliderImages.length - 1].image_path = res.created.image_path

          this.$nextTick(() => { this.showSlider = true })
          this.form = {title: '', description: '', more: '', image_path: '', file: {}}
          this.$v.$reset()
        })
      } else {
        this.$v.form.$touch()
      }
    },
    changeFileInput (files) {
      if (!files.length) {
        return
      }
      this.form.file = files[0]
      this.createImage(this.form.file)
    },
    createImage (file) {
      const reader = new FileReader()
      reader.onload = (e) => {
        this.form.file = file
        this.form.image_path = e.target.result
      }
      reader.readAsDataURL(file)
    },

    /**
     * Drag and drop
     */
    startDrag(item, i, e) {
      this.startLoc = e.clientY;
      this.dragging = true;
      this.dragFrom = item;
    },
    finishDrag(item, pos) {
      this.sliderImages.splice(pos, 1)
      this.sliderImages.splice(this.over.pos, 0, item);
      this.over = {}
    },

    onDragOver(item, pos, e) {
      const dir = (this.startLoc < e.clientY) ? 'down': 'up';
      setTimeout(() => {
        this.over = { item, pos, dir };
      }, 50)
    }
  },
  mounted() {
    this.getSliderPageDataAction().then((res) => {
      if (res.sliders.length) {
        this.sliderImages = [...res.sliders]
        this.showSlider = !this.showSlider
      }
    })
  }
}
</script>
<style>
.carousel-item {
  height: 288px;
}
.sliderButtonNav {
  display: none;
}
.sliderButtonNav .button-groups{
  width: 80px;
  margin-top: 0;
}
.carousel-item.active + .sliderButtonNav {
  position: absolute;
  z-index: 10;
  bottom: 10px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 15px;
}
.drag-drop-list {
  background: #fafafa;
}
.drag-drop-list li {
  list-style: none;
  margin: 10px 15px;
  width: 20%;
}
.drag-drop-list li img {
  width: 100%;
  height: 150px;
}
</style>