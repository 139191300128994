import axios from "axios";

export const siteConstructor = {
  state: {
    slidersPageData: [],
    pagesData: [],
    pageData: [],
  },
  mutations: {
    setSliderPageData(state, payload) {
      state.slidersPageData = payload
    },
    setPagesData(state, payload){
      state.pagesData = payload
    },
    setPageData(state, payload){
      state.pageData = payload
    },

  },

  actions: {
    async saveSliderAction (context, payload) {
      try {
        context.commit('changeLoading', true)
        const response = await axios.post('/site-constructor/slider/create', payload, {
           headers: {
             'Content-Type': 'multipart/form-data'
           },
        })
        context.commit('changeLoading', false)
        return response.data
      } catch (e) {
        context.commit('changeLoading', false)
        throw e
      }
    },
    async removeSliderAction(context, payload){
      try {
        const response = await axios.post('/site-constructor/slider/remove', payload)
        return response.data
      }catch (e) {
        throw e
      }
    },
    async getSliderPageDataAction(context) {
      try {
        context.commit('changeLoading', true)
        const {data} = await axios.get('/site-constructor/sliders')
        context.commit('changeLoading', false)
        context.commit('setSliderPageData', data)
        return data
      } catch (e) {
        throw  e
      }
    },

    async getPagesDataAction(context){
      try {
        context.commit('changeLoading', true)
        const data = await axios.get('/site-constructor/pages')
        context.commit('changeLoading', false)
        context.commit('setPagesData', data.data.pages)
        return data.data.pages
      }catch (e){
        throw e
      }
    },
    async getPageDataByIdAction(context, payload){
      try {
        const data = await axios.post('/site-constructor/page', payload, {
          headers: {
            'Content-Type': 'application/json'
          },
        })
        context.commit('setPageData', data.data.page)
        return data.data.page
      }catch (e) {
        throw e
      }
    },
    async savePageDataAction(context, payload){
      try {
        const response = await axios.post('/site-constructor/page/edit', payload)
        return response.data
      }catch (e) {
        throw e
      }
    },

    async removeAddedImageFromPage(context, payload){
      axios({
        url: "/site-constructor/page/edit/image/remove-all",
        method: "POST",
        data: payload,
      })
          .catch(e => {
            throw e
          });
    }



  },
  getters: {
    getSliderPageData: state => state.slidersPageData,
    getPageData: state => state.pagesData,
    getPageDataById: state => state.pageData,
  }
}
